import * as React from 'react';
import { IDetailsListTransmittalProps } from './IDetailsListTransmittalProps';
import {IDocumentStatus} from './IDocumentStatus'
import {ITransmittalSummary} from './ITransmittalSummary'
import {ITransmittalItem} from './ITransmittalItem'
//import {IControlDocument} from './IControlDocument'
import CommentsCounting from './CommentsCounting'
//import {IComment} from './IComment'
import {DateHelper} from './DateHelper'

import {DetailsList, DetailsHeader,
  //DetailsListLayoutMode, ScrollToMode,
  IColumn,IDetailsHeaderProps,IDetailsList,IGroup,IRenderFunction, mergeStyles, Overlay, Selection,
  IDetailsListStyles,
  ScrollablePane,
  mergeStyleSets,
  ZIndexes,
  // Sticky,
  // StickyPositionType
} from '@fluentui/react';

// import { DetailsHeader, DetailsList, IColumn, IDetailsHeaderProps, IDetailsList, IGroup, 
  // IRenderFunction, mergeStyles, Overlay, ScrollablePane, Selection} from 'office-ui-fabric-react';
import { CommandBar, ICommandBarItemProps } from 'office-ui-fabric-react/lib/CommandBar';
import { IButtonProps,DefaultButton,BaseButton,Button } from 'office-ui-fabric-react/lib/Button';
import { Label } from 'office-ui-fabric-react/lib/Label';
import DocStatusDropdown from './DocStatusDropdown'
import { SearchBox, ISearchBoxStyles } from 'office-ui-fabric-react/lib/SearchBox';
import {IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import axios from 'axios';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import  JSZip from 'jszip';
import {saveAs} from 'file-saver';
//import { IControlDocument } from './IControlDocument';
// import Overlay1 from './Overlay_1'
import { PnPClientStorage } from 'sp-pnp-js';


//#region  popup
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton} from 'office-ui-fabric-react/lib/Button';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import { TextField } from 'office-ui-fabric-react';




// const dragOptions = {
//   moveMenuItemText: 'Move',
//   closeMenuItemText: 'Close',
//   menu: ContextualMenu,
// };
// const modalPropsStyles = { main: { maxWidth: 450 } };


// const gridStyles: Partial<IDetailsListStyles> = {
//   root: {
//     overflowX: 'hidden',
//     selectors: {
//       '& [role=grid]': {
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'start',
//         height: '60vh',
//       },
//     },
//   },
//   headerWrapper: {
//     flex: '0 0 auto',
//   },
//   contentWrapper: {
//     flex: '1 1 auto',
//     overflow: 'hidden',
//     maxWidth: '100%',
//   },
// };


const spinnerStyles= {
  circle: {
    height: 25,
    width: 25,
    borderWidth: 4,
    ZIndexes:999
  }
}

const spinnerWrapper = mergeStyles({
  display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'
});

const dialogContentProps = {
  type: DialogType.normal,
  title: 'Submit Transmittal Return',
  subText: 'Please copy the below auto generated transmittal number for your reference:',
};


//let returnTrasnmittalNO = "";
const buttonStyle = mergeStyles({
  width: 'auto',
});

//#endregion

const controlWrapperClass = mergeStyles({
  display: 'flex',
  flexWrap: 'wrap',
});

const searchWrapperClass = mergeStyles({
  float:'right',
  margin: '10px',
  display:'block',
});

const tabTableWrapper = mergeStyles({
  //width:'100%',
  width:'1470px',
  overflowY:'auto',
  //overflowX:'scroll',
});

const detailsListWrapper = mergeStyles({
  width: '99% !important',
  margin:'0px',
  overflowY:'auto',
});

const gridStyles: Partial<IDetailsListStyles> = {
  root: {
    overflowX: 'scroll',
    selectors: {
      '& [role=grid]': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        height: '60vh',
      },
    },
  },
  headerWrapper: {
    flex: '0 0 auto',
  },
  contentWrapper: {
    flex: '1 1 auto',
    overflow: 'hidden',
  },
};

const overlayStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // Full viewport height
};
const classNames = mergeStyleSets({
  header: {
    margin: 0,
  },
  row: {
    flex: '0 0 auto',
  },
  focusZone: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  selectionZone: {
    height: '100%',
    overflow: 'hidden',
  },
});

//const addItemButtonStyles: Partial<IButtonStyles> = { root: { margin: margin } };

export interface IDocDownloaded {
  docNo:string;
  releasedVersion:string;
  docId:number;
  cusDocNo:string;
  serverRelativeUrl:string;
  transmittalItemId:number;
}

export interface IDetailsListGroupedExampleState {
  //items: IDetailsListGroupedExampleItem[];
  columns: IColumn[];
  items: ITransmittalItem[];
  itemsT:ITransmittalItem[]; // use for the items before search
  groups: IGroup[];
  showItemIndexInView: boolean;
  isCompactMode: boolean;
  docStatusOptions:IDropdownOption[];
  docStatus:IDocumentStatus[];
  isBusy:boolean;
  isHistoryTransmittal:boolean; // used to filter pending transmittal or history transmittal
  search?:string; //the search string in search box
  downloaded:boolean;
  selectionDetails:string;
  selectedDocs:IDocDownloaded[];
  submitting:boolean;
  returnTransmittal?:string;
  showPopupDialog:boolean;
  popupBusy:boolean;
  serviceMessage:string;
  allowCustomerReturn:boolean;
  message4DisableReturn:string;
  txtCurrentFilter:string;
}

//var _mode:string;
//_mode = "New";

const hideStyle = {display : 'none'};
const overflowProps: IButtonProps = { ariaLabel: 'More commands' };
// let summaryId =""
export const CommandBarBasicExample: React.FunctionComponent = () => {
  return (
    <div>
      <CommandBar
        items={_items}
        overflowItems={_overflowItems}
        overflowButtonProps={overflowProps}
        farItems={_farItems}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

function getCommandBar(allowSubmit:boolean,messToUser:string,isHistory:boolean) : ICommandBarItemProps[] 
{
  return [
    {
      key: 'filterStatus',
      //text: 'Filter Status',
      text: isHistory? "Returned History" : "Pending Transmittals",
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Filter' },
      subMenuProps: {
        items: [
          {
            key: 'pending',
            text: 'Pending Transmittals',
            iconProps: { iconName: 'RecurringTask' },
            onClick: () => {
              let element: HTMLElement = document.getElementById("btnFilterTransmittal") as HTMLElement;
              element.textContent = 'pending'
              element.click();
            }
          },
          {
            key: 'returned',
            text: 'Returned History',
            iconProps: { iconName: 'TaskLogo' },
            onClick: () => {
              let element: HTMLElement = document.getElementById("btnFilterTransmittal") as HTMLElement;
              element.textContent = 'history'
              element.click();
            }
          },
        ],
      },
    },
    {
      key: 'submitTran',
      text: 'Submit',
      disabled:!allowSubmit,
      title:messToUser,
      iconProps: { iconName: 'Upload' },
      onClick: () => { 
        let element: HTMLElement = document.getElementById("btnSubmissionHidden") as HTMLElement;
        element.click();
    },
    },
    {
      key: 'download',
      text: 'Download',
      iconProps: { iconName: 'Download' },
      onClick: () => {
        let element: HTMLElement = document.getElementById("btnDownloadHide") as HTMLElement;
        element.click();
      },
    },
  ];
}

const _items: ICommandBarItemProps[] = [
  {
    key: 'filterStatus',
    text: 'Filter Status',
    cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
    iconProps: { iconName: 'Filter' },
    subMenuProps: {
      items: [
        {
          key: 'pending',
          text: 'Pending Transmittals',
          iconProps: { iconName: 'RecurringTask' },
          onClick: () => {
            let element: HTMLElement = document.getElementById("btnFilterTransmittal") as HTMLElement;
            element.textContent = 'pending'
            element.click();
          }
        },
        {
          key: 'returned',
          text: 'Returned History',
          iconProps: { iconName: 'TaskLogo' },
          onClick: () => {
            let element: HTMLElement = document.getElementById("btnFilterTransmittal") as HTMLElement;
            element.textContent = 'history'
            element.click();
          }
        },
      ],
    },
  },
  {
    key: 'submitTran',
    text: 'Submit',
    iconProps: { iconName: 'Upload' },
    onClick: () => { 
      let element: HTMLElement = document.getElementById("btnSubmissionHidden") as HTMLElement;
      element.click();
  },
  },
  // {
  //   key: 'comments',
  //   text: 'Comments',
  //   cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
  //   iconProps: { iconName: 'FileComment' },
  //   subMenuProps: {
  //     items: [
  //       {
  //         key: 'addComments',
  //         text: 'Add Comment',
  //         iconProps: { iconName: 'Add' },
  //         ['data-automation-id']: 'newEmailButton', // optional
  //         onClick: () => {let element: HTMLElement = document.getElementById("btnNewHidden") as HTMLElement;
  //       element.click();
  //         },
  //       },
  //       {
  //         key: 'disComments',
  //         text: 'Display Comments',
  //         iconProps: { iconName: 'List' },
  //         onClick: () => {let element: HTMLElement = document.getElementById("btnListHidden") as HTMLElement;
  //         element.click();
  //         },
  //       },
  //     ],
  //   },
  // },
  {
    key: 'download',
    text: 'Download',
    iconProps: { iconName: 'Download' },
    onClick: () => {
      let element: HTMLElement = document.getElementById("btnDownloadHide") as HTMLElement;
      element.click();
    },
  },
];

const _overflowItems: ICommandBarItemProps[] = [];
const _farItems: ICommandBarItemProps[] = [];
const searchBoxStyles: Partial<ISearchBoxStyles> = { 
  root: { width: 200,float:'right' } 
};

export default class DetailsListTransmittal_V2 extends React.Component<IDetailsListTransmittalProps, IDetailsListGroupedExampleState,ICommandBarItemProps> {
  private _root = React.createRef<IDetailsList>();
  private _columns: IColumn[];
  private _options : IDropdownOption[] = [];
  private _selection: Selection;

  constructor(props: IDetailsListTransmittalProps) {
    super(props);

    this._selection = new Selection({
      onSelectionChanged: () => {
        let element: HTMLElement = document.getElementById("btnDownloadHide") as HTMLElement;
        if (this._selection.getSelection().length > 0)
        {
          element.textContent= (this._selection.getSelection()[0] as ITransmittalItem).Id.toString();
          this.setState({
            selectionDetails: this._getSelectionDetails(),
            selectedDocs:this._getSelectedDocs(),
          });
        }
      },
    });
    this.state = {
      columns:[],
      items: [],
      itemsT: [],
      groups: [],
      showItemIndexInView: false,
      isCompactMode: true,
      docStatusOptions:[],
      isBusy:false,
      isHistoryTransmittal:false,
      downloaded:false,
      submitting:false,
      selectionDetails:this._getSelectionDetails(),
      selectedDocs:this._getSelectedDocs(),
      docStatus:[],
      showPopupDialog:false,
      returnTransmittal:"",
      popupBusy:true,
      serviceMessage:"Wait the system to generate the return transmittal number for you ...",
      allowCustomerReturn:true,
      message4DisableReturn:"",
      txtCurrentFilter:"Pending"
    };

    this._columns = [
      { key : 'ItemNo',name : 'Item No.',fieldName:'ItemNo', minWidth: 80, maxWidth: 100,isResizable: true,isMultiline:true,isSorted:true,onColumnClick: this._onColumnClick,},
      //{ key: 'Id', name: 'Id', fieldName: 'Id', minWidth: 20, maxWidth: 50, isResizable: true },
      //{ key: 'TransmittalNo', name: 'Transmittal No', fieldName: 'TransmittalNo', minWidth: 100, maxWidth: 230,isResizable: true },
      { key: 'CustomerDocumentNo', name: 'Document No.', fieldName: 'CustomerDocumentNo', minWidth: 100, maxWidth: 200,isResizable: true,isMultiline:true, isSorted:true,onColumnClick: this._onColumnClick,},
      { key: 'docSize', name: 'Size', fieldName: 'docSize', minWidth: 40, maxWidth: 60,isResizable: true,isSorted:true,onColumnClick: this._onColumnClick, },
      { key: 'DocumentTitle', name: 'Document Title', fieldName: 'DocumentTitle', minWidth: 120, maxWidth: 260, isResizable: true, isMultiline:true,isSorted:true,onColumnClick: this._onColumnClick,},
      { key: 'DocRevisionNo', name: 'Revision', fieldName: 'DocRevisionNo', minWidth: 50, maxWidth: 70,isResizable: true, isSorted:true,onColumnClick: this._onColumnClick,},
      { key: 'ReturnNumber', name: 'Return Transmittal Number', fieldName: 'ReturnNumber', minWidth: 50, maxWidth: 70,isResizable: true,isSorted:true,onColumnClick: this._onColumnClick, },
      { key: 'ReturnBy', name: 'Return By', fieldName: 'ReturnBy', minWidth: 50, maxWidth: 70,isResizable: true,isSorted:true,onColumnClick: this._onColumnClick, },
      { key: 'RequestForDescription', name: 'Request For', fieldName: 'RequestForDescription', minWidth: 100, maxWidth: 200,isResizable: true,isSorted:true,onColumnClick: this._onColumnClick, },
      { key: 'CurrentStatusId', name: 'Status', fieldName: 'CurrentStatusId', minWidth: 100, maxWidth: 200,isMultiline:true, isResizable: true, isSorted:true,onColumnClick: this._onColumnClick,},
      { key:'comment', name:'Comments',fieldName:'comment',minWidth:100,maxWidth:120,isResizable:true,isSorted:true,onColumnClick: this._onColumnClick,},
      { key: 'Pages', name: 'Pages', fieldName: 'Pages', minWidth: 40, maxWidth: 60,isResizable: true,isSorted:true,onColumnClick: this._onColumnClick, },
      { key: 'RequestReturnDate', name: 'Request Return', fieldName: 'RequestReturnDate', minWidth: 100, maxWidth: 200,isResizable: true, isSorted:true,onColumnClick: this._onColumnClick,},
      //{ key: 'stage', name: 'Stage', fieldName: 'stage', minWidth: 50, maxWidth: 100,isResizable: true },
      { key: 'ReturnDate', name: 'Return Date', fieldName: 'ReturnDate', minWidth: 100, maxWidth: 200,isResizable: true, isSorted:true, onColumnClick: this._onColumnClick, },
      
    ];

  }

  private _getSelectionDetails(): string {
    let selectionCount;
    // if(this.props.summaryId !=""){
    //   selectionCount = 1;
    //   switch (selectionCount) {
    //     case 0:
    //       return 'No items selected';
    //     case 1:
    //       return '1 item selected: ' + (this._selection.getSelection()[0] as ITransmittalItem).DocumentTitle;
    //     default:
    //       return `${selectionCount} items selected`;
    //   }
    // }
    // else{
      selectionCount = this._selection.getSelectedCount();
      switch (selectionCount) {
        case 0:
          return 'No items selected';
        case 1:
          return '1 item selected: ' + (this._selection.getSelection()[0] as ITransmittalItem).DocumentTitle;
        default:
          return `${selectionCount} items selected`;
      }
    //}
    
  }

  private _getSelectedDocs() : IDocDownloaded[] 
  {
    let results : IDocDownloaded[] = []; 
    this._selection.getSelection().forEach(item =>{
      let traItem = item as ITransmittalItem;
      results.push({
        docNo:traItem.DocumentNo!,
        releasedVersion:traItem.DocRevisionNo!,
        docId:traItem.DocId!,
        cusDocNo:traItem.CustomerDocumentNo!,
        serverRelativeUrl:traItem.DocumentUrl!,
        transmittalItemId:traItem.Id,
      })
    })
    return results;
  }


  componentDidMount() {
    const url = require('url');
    const querystring = require('querystring');
    let rawUrl = window.location.href;
    let parsedUrl = url.parse(rawUrl);
    let parsedQs = querystring.parse(parsedUrl.query);
    //summaryId += parsedQs.summaryId; // Gives "ProjectNo"
    
    if (this.props.currentUserInProject !== undefined)
    {
      this._buildDetailsListItems();
    }
    if (this.props.summaryId !== "") {
      this._setSelectedItems();
    }

}

componentDidUpdate(previousProps:IDetailsListTransmittalProps, previousState:IDetailsListGroupedExampleState) {
  if (this.props.currentUserInProject !== undefined && (previousProps.projectNo !== this.props.projectNo || previousProps.currentUserInProject !== this.props.currentUserInProject)) {
    this._buildDetailsListItems();
  }
  if (previousState.isHistoryTransmittal !== this.state.isHistoryTransmittal)
  {
    this._buildDetailsListItems();
  }
  if (previousState.search !== this.state.search)
  {
    this._searchApplyNew();
  }
  if (previousState.downloaded === false && this.state.downloaded === true)
  {
    if (this._selection.getSelection().length === 0)
    {
      alert('Please select your items');
      this.setState({
        downloaded:false,
      });
    }
    else if (this._selection.getSelection().length === 1)
    {
      this._downloadSelectedItems();
    }
    else if (this._selection.getSelection().length > 1)
    {
    this._downloadAndZipSelectedItems();
    }
  }
  if (previousState.submitting === false && this.state.submitting === true)
  {
    //#region open popupdialog
       
    let projectNo = this.props.projectNo;
    let currentUser = this.props.currentUserInProject;
    
    if (projectNo !== undefined && currentUser !== undefined) {
      // if(this.state.showPopupDialog === true){
        this._generateTransmittalnumber();
      // }
    }
       
    this.openDialog();

    //#endregion

    // //#region call submit
    // //alert('call submit return transmittal here');
    // //check the document status before submit
    // let statusCheckFlag = true;
    // let oList = this._selection.getSelection();

    // for (let i = 0; i < oList.length; i++) {
    //   let traItem = oList[i] as ITransmittalItem;
    //   if (traItem.CurrentStatusId === 0) 
    //   {
    //     statusCheckFlag = false;
    //     break;
    //   }
    // }
    // if (false === statusCheckFlag)
    // {
    //   alert(`Please select status for each of transmittal Item.`);
    //   this.setState({
    //     isBusy:false,
    //     submitting:false,
    //   })
    //   return;
    // }
    // this.setState({
    //   isBusy:true,
    // })
    // let transIds: number[] = [];
    // this._selection.getSelection().forEach(item => {
    //   transIds.push((item as ITransmittalItem).Id);
    // });
    // let request = {
    //   projectNo : this.props.projectNo,
    //   transIds: transIds,
    //   currentUser:this.props.currentUserInProject,
    //   //userId:this.props.currentUserInProject!.key,
    // }
    // axios.post(`/api/v1/transmittal/return`,request).then((result) => {
    //   console.log(result);
    //   this.setState({
    //     isBusy:false,
    //     submitting:false,
    //   });
    //   this._buildDetailsListItems();
    // });
    //#endregion
  }
}
    private _downloadSelectedItems()
    {
      if (this._selection.getSelection().length > 0)
      {
        if (this.state.isBusy === false)
        {
          this.setState({
            isBusy:true,
          });
        }
         let srUrl = this.state.selectedDocs[0].serverRelativeUrl;
         let fileType = srUrl.substring(srUrl.lastIndexOf("."));
         let fileName = `${this.state.selectedDocs[0].cusDocNo}_${this.state.selectedDocs[0].releasedVersion}${fileType}`;
         let tranItemId = this.state.selectedDocs[0].transmittalItemId;
         let userId = this.props.currentUserInProject?.key;
         axios.get(`/api/v1/singleFileDownload?projectNo=${this.props.projectNo}&serverReltiveURL=${srUrl}&userId=${userId}&transmittalId=${tranItemId}`,
         {responseType: 'blob',})
         .then((res)=>{
           const url = window.URL.createObjectURL(new Blob([res.data]));
           const link = document.createElement('a');
           link.href = url;
           link.setAttribute('download', `${fileName}`); //or any other extension
           document.body.appendChild(link);
           link.click();
           this.setState({
             isBusy:false,
             downloaded:false,
           });
          })};
    }

    private _downloadAndZipSelectedItems()
    {
      var zipFilename = `${this.props.projectNo}_ContrlDocs.zip`;		
      var zip = new JSZip();
      var allPromise = [];
      if (this._selection.getSelection().length > 0)
      {
        if (this.state.isBusy === false)
        {
          this.setState({
            isBusy:true,
          });
        }
        let countFile = 0;
        for (var i = 0; i < this.state.selectedDocs.length; i++)
        {
          let cFile : IDocDownloaded = this.state.selectedDocs[i] as IDocDownloaded;
          let srUrl = cFile.serverRelativeUrl;
          let fileType = srUrl.substring(srUrl.lastIndexOf("."));
          let fileName = `${this.state.selectedDocs[i].cusDocNo}_${this.state.selectedDocs[i].releasedVersion}${fileType}`;
          let c_promise = new Promise((resolve, reject)=>{}); 
          allPromise.push(c_promise);
          let tranItemId = this.state.selectedDocs[i].transmittalItemId;
          let userId = this.props.currentUserInProject?.key;
          axios.get(`/api/v1/singleFileDownload?fileName=${fileName}&projectNo=${this.props.projectNo}&serverReltiveURL=${srUrl}&userId=${userId}&transmittalId=${tranItemId}`,
          {responseType: 'blob',})
          .then((res)=>{
            countFile ++;
           zip.file(fileName, res.data, {binary:true});
           if  (countFile === this.state.selectedDocs.length)
           {
            zip.generateAsync({type:'blob'}).then(function(content) {
              saveAs(content, zipFilename);  
            }).then( ()=>
            {
               this.setState({
                 downloaded:false,
                 isBusy:false
                });
            });
           }
          });
        }
      }
    }
    

  public render(): React.ReactElement<IDetailsListTransmittalProps> {
    //const { items, groups, isCompactMode } = this.state;
    const { items, groups,} = this.state;
    const focusZoneProps = {
      className: classNames.focusZone,
      'data-is-scrollable': 'true',
    } 
    return (
      //<div className={tabTableWrapper}>
        <div>
        <table className={detailsListWrapper}>
          <tbody>
            <tr>
              <td>
                <div>
              <CommandBar
          items={getCommandBar(this.state.allowCustomerReturn,this.state.message4DisableReturn,this.state.isHistoryTransmittal)}
          overflowItems={_overflowItems}
          overflowButtonProps={overflowProps}
          farItems={_farItems}
          ariaLabel="Use left and right arrow keys to navigate between commands"/>
        </div></td>
              <td>
                <div className={searchWrapperClass}>
                  <SearchBox styles={searchBoxStyles} placeholder="Search" onChange={this.setSearchState} />
                  </div>
                </td>
            </tr>
            <tr>
              <td colSpan={2}><div>{this.state.selectionDetails}</div></td>
            </tr>
            <tr>
              <td colSpan={2}>  
             
              { (this.state.isBusy) ? <Overlay style={overlayStyle}><div className={spinnerWrapper}><Spinner styles={spinnerStyles}></Spinner></div></Overlay> : null } 
              
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
        
              <div className={tabTableWrapper}>
                <DetailsList componentRef={this._root} items={items} 
                groups={groups} 
                columns={this._columns}
                getKey={this._getKey} selection={this._selection}
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                ariaLabelForSelectionColumn="Toggle selection"
                checkButtonAriaLabel="Row checkbox"
                onRenderDetailsHeader={this._onRenderDetailsHeader}
                // layoutMode={DetailsListLayoutMode.fixedColumns}
                //styles={gridStyles}
                groupProps={{
                  showEmptyGroups: true,
                }}
                // onShouldVirtualize={() => false}
                onRenderItemColumn={ (item, index, column) => this._onRenderColumnFunction(item, index, column,this.state.allowCustomerReturn)}
                styles={gridStyles}
                focusZoneProps={focusZoneProps}
                selectionZoneProps={{
                  className: classNames.selectionZone,
                }}
                compact={true}/>     
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className={controlWrapperClass}>
        </div>
        <DefaultButton style={hideStyle} id='btnFilterTransmittal' onClick={e=> this._setTransmittalFilterState(e)} text='btnFilterHide' ></DefaultButton>
        <DefaultButton style={hideStyle} id='btnDownloadHide' onClick={()=> this.setDownloadedState()} text='downloadHide' ></DefaultButton>
        <DefaultButton style={hideStyle} id='btnSubmissionHidden' onClick={() => this.setSubmissionState() } text = 'submissionHide'></DefaultButton>
        {/* {this.state.submitting ? <Overlay1 message={'Process is on going, please wait...'}></Overlay1> : null} */}
        {this.state.submitting ? <Dialog
          isOpen={this.state.showPopupDialog!}
          onDismiss={()=>this.hideDialog()}
          isBlocking = {true}
          dialogContentProps={dialogContentProps}
          closeButtonAriaLabel="Close">
          {/* <TextField id="txtTraNo" value={this.state.returnTransmittal}></TextField> */}
          <TextField contentEditable="true" id="txtTraNo" value={this.state.returnTransmittal} onChange={this.setTransmittalNo}></TextField>
          <Label id="lblStatus">{this.state.serviceMessage}</Label>
          { this.state.popupBusy ? <Spinner styles={spinnerStyles}/> : 
          <DialogFooter>
            <PrimaryButton disabled={this.state.serviceMessage.length > 0} className={buttonStyle} onClick={()=>this.submitTra()} id="btnSave" text="Send" />
            <DefaultButton className={buttonStyle} onClick={()=>this.hideDialog()} text="Don't Send" id="btnCancel" />
          </DialogFooter>}
       </Dialog> : null}
       <DefaultButton style={hideStyle} secondaryText="Opens the Sample Dialog" onClick={()=>this.openDialog()} text="Open Dialog" />
      </div>
    );
    
  }

  setSearchState = (event:any) =>
  { 
    if (event == null)
    {
      this.setState({
        search: "",
      });
    }
    else if (event.target !== undefined)
    {
      // console.log(`set search as ${event.target.value}`);
      this.setState({
        search: event.target.value,
      });
    }
  }

  private hideDialog():void
  {
    this.setState({
      submitting:false,
      showPopupDialog:false,
      popupBusy:true,
      isBusy:false,
    });
  }

  private openDialog(): void
  {
    this.setState({
      showPopupDialog:true,
    })
  }

  private _setSelectedItems(): void {
    const storage = new PnPClientStorage();
    let summaryId = this.props.summaryId;
    let selectedItems = storage.session.get("ItemNo");
    if (selectedItems) {
      this._selection.setChangeEvents(false, true);
      //this._selection.setKeySelected((2).toString(), true, false);
      //this._selection.setItems(items);
      selectedItems.forEach((summaryId: string) => {
          this._selection.setKeySelected(summaryId, true, false);
        });
      this._selection.setChangeEvents(true, true);
    }
  }

  private _getKey(item: any, index?: number): string {
    return item.Id.toString();
  }

  private _searchApply(keystring:string) : void {
    //alert(keystring);
    this.setState({
      search:keystring,
      isBusy:true,
      items:[],
      groups:[],
    });
  }

  private _searchApplyNew()
  {
    if (this.state.search !== undefined && this.state.search !== null)
    {
    this.setState({
      isBusy:true,
    });
    let _allTItems = this.state.itemsT;
    let _groups : IGroup[] = [];
    let indexItem : number = -1;
    let summaryTmp: ITransmittalSummary  = {} as ITransmittalSummary;
    let countTmp:number = 0;
    let countTmps:number[] = [];
    if (this.state.search !== null && this.state.search !== undefined)
    {
      let keystring = this.state.search.toLowerCase();
      _allTItems = _allTItems.filter((oitem:any) =>
        {
          return (oitem.ItemNo + "").toLowerCase().includes(keystring) 
        || (oitem.TransmittalNo+ "").toLowerCase().includes(keystring) 
        || (oitem.DocumentTitle+ "").toLowerCase().includes(keystring) 
        || (oitem.CustomerTransmittalNo+ "").toLowerCase().includes(keystring) 
        || (oitem.RequestForDescription+ "").toLowerCase().includes(keystring) 
        || (oitem.CustomerDocumentNo+ "").toLowerCase().includes(keystring) 
        || (oitem.DocumentNo+ "").toLowerCase().includes(keystring) 
        || (oitem.TransmittalSummaryGroupName+ "").toLowerCase().includes(keystring)
        || (oitem.ReturnNumber+ "").toLowerCase().includes(keystring)
        || (oitem.ReturnBy == null ? '' : oitem.ReturnBy).toLowerCase().includes(keystring) 
        || (oitem.DocRevisionNo+ "").toLowerCase().includes(keystring)
      })
    };
 
    _allTItems.forEach(item => {
      indexItem += 1;
      if (summaryTmp !== null && summaryTmp.Id === item.SummaryId)
      {
        if (countTmps.length > 0)
         {
           let lastIdx = countTmps.length -1;
           countTmps[lastIdx] += 1;
         }
       }
       else
       {
           //means new transmittal summary , so that create new group
           countTmp = 1;
           summaryTmp.Id = item.SummaryId!;
           countTmps.push(countTmp);
           let tGroup : IGroup = {
             key:item.SummaryId!.toString(),
             name:item.TransmittalSummaryGroupName,
             startIndex:indexItem,
             count:countTmp
           }
       _groups.push(tGroup);
     }
   });
      
      //   //re-assign the groups' count
      for(var i = 0; i < _groups.length; i ++)
      {
      _groups[i].count = countTmps[i];
      }

      this.setState({
        items:_allTItems,
        columns:this._columns,
        groups:_groups,
        isBusy:false,
        selectionDetails:''
      });
    }
    
  }

  private _setTransmittalFilterState(e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>)
  {
    //alert((e.target as HTMLElement).title);
    if (this.state.isHistoryTransmittal !== ((e.target as HTMLElement).textContent === "history" ? true : false))
    {
    this.setState({
      isHistoryTransmittal:(e.target as HTMLElement).textContent === "history" ? true : false,
      isBusy:true,
      items:[],
      groups:[],
      //columns:[],
    });
  }
  }

  private setDownloadedState()
  {
    this.setState({
      downloaded:true,
    });
  }

  private setSubmissionState ()
  {
    // need to check whether the status has been selected from the status column, 
    //if not should not be able to submit
    if  (this._selection.count >0)
    {
      this.setState({
      submitting:true,
      isBusy:true,
    });
  }
  else
  {
    alert("Please select at least one item");
  }
  }

  private _triggerPanel = () : void =>{
    let element: HTMLElement = document.getElementById("btnHidden") as HTMLElement;
element.click();
  }

    private async _getTransmittalSummaries() : Promise<any>
   {
      let result = await axios.get(`/api/v1/transmittalSumItems/getAll?projectNo=${this.props.projectNo}&userId=${this.props.currentUserInProject?.key}`);
      return result.data.body;
  }

  private async _getSummariesAndItemsForUser() : Promise<any> {

    let req = {
      projectNo : this.props.projectNo,
      user: this.props.currentUserInProject!,
    }
  //let result = await axios.get(`/api/v1/transmittalItems/forUser?projectNo=${this.props.projectNo}&user=${this.props.currentUserInProject!}`);
    let result = await axios.post(`/api/v1/transmittalItems/forUser`,req);
    return result.data.body;   
  }


  // private async _getTransmittalSummaries() : Promise<any>
  //  {
  //     if (this.props.projectNo != null && this.props.currentUserInProject != null)
  //     {
  //         //replace the hardcode value for current projectuser Id with 
  //         let userId = this.props.currentUserInProject.key;
  //       let web = Web(this.props.siteUrl + "/" + this.props.projectNo);
  //     let response = await  web.lists.getByTitle("Transmittal Summary").items.select("Id","Title","CustomerTransmittalNo","From_x0020_User/Id","To_x0020_User/Id","RevisionNo/Title","SequenceStageCode/Id","SequenceStageCode/Title")
  //       .expand("From_x0020_User/Id","To_x0020_User/Id","RevisionNo/Title","SequenceStageCode/Id").
  //       filter('To_x0020_User/Id eq 92 or To_x0020_User/Id eq 88').get();
  //       return response;
  //     }
  //     else
  //     {
  //       return Promise.resolve(1);
  //     }
  // }

  private async _getAllControlDocuments(): Promise<any> {
    let result = await axios.get(`/api/v1/controlDocuments/getContlDocuments?projectNo=${this.props.projectNo}`);
    return result.data.body;
    // if (this.props.projectNo != null && this.props.currentUserInProject != null)
    // {
    //     let userId = this.props.currentUserInProject.key;
    //   let web = Web(this.props.siteUrl + "/" + this.props.projectNo);
    //   let response = await web.lists.getByTitle("Documents").items.select("Id","Title","Document_x0020_Title","Document_x0020_No/Id","Document_x0020_No/DocumentNo","Revision_x0020_No/Id","Pages0","ReleasedCusVersion","FileRef","Document_x0020_Type","Modified")
    //   .expand("Document_x0020_No/Id","Revision_x0020_No/Id").get();
    //   return response;
    // }
    // else
    // {
    //     return Promise.resolve(2);
    // }
  }

  private async _getAllTransmittalItems(): Promise<any>{
    let result = await axios.get(`/api/v1/transmittalItems/getAll?projectNo=${this.props.projectNo}`);
    return result.data.body;
    
    // if (this.props.projectNo != null && this.props.currentUserInProject != null)
    // {
    // let userId = this.props.currentUserInProject.key;
    // let web = Web(this.props.siteUrl + "/" + this.props.projectNo);
    // let response = await web.lists.getByTitle("Transmittal Items").items.select("Id","Title","Summary_x0020_Id/Id","Scheduled_x0020_Return_x0020_Dat","Return_x0020_Date","Remarks",
    // "Document_x0020_Id/Id","Transmittal_x0020_request_x0020_/Id","Transmittal_x0020_request_x0020_/Description","Document_x0020_Status/Id","Document_x0020_Status/Title",
    // "Document_x0020_Status/Description","DcrItemId/Id")
    // .expand("Summary_x0020_Id/Id","Document_x0020_Id/Id","Document_x0020_Status/Id","DcrItemId/Id","Transmittal_x0020_request_x0020_/Id").get();
    // return response;
    // }
    // else
    // {
    //    return Promise.resolve(3);
    // }
  }

  private async _getTransmittalComments(): Promise<any>{
    let result = await axios.get(`/api/v1/transmittalItems/allComments?projectNo=${this.props.projectNo}`);
    return result.data.body;
  }

  private async _getPdfTransmittalDownloadPath():Promise<any>{
    let result = await axios.get(`/api/v1/pdfTransmittalDownloadPath`);
    return result.data.body;
  }

  private async _getDocumentStatus():Promise<any>{
    let result = await axios.get(`/api/v1/documentStatus/getDocStatus?projectNo=${this.props.projectNo}`);
    return result.data.body;
    
    // if (this.props.projectNo != null && this.props.currentUserInProject != null)
    // {
    // let userId = this.props.currentUserInProject.key;
    // let web = Web(this.props.siteUrl + "/" + this.props.projectNo);
    // let response = await web.lists.getByTitle("Master Data").items.select("Id","Title","TypeCode","Description").filter("TypeCode eq '001'").get();
    // return response;
    // }
    // else
    // {
    //    return Promise.resolve(4);
    // }
  }

  private async _getCustomerReturnControl():Promise<any>{
    let result = await axios.get(`/api/v1/transmittal/getCustomerReturnControl?projectNo=${this.props.projectNo}`);
    return result.data.body;
  }

  private async _buildDetailsListItems()
  { 
    if (this.state.isBusy === false)
    {
      this.setState({
        isBusy:true,
      });
    }

    //add this one and remove promise1 and promise3  to optimize the performance
    const promiseItemsAndSummaries = this._getSummariesAndItemsForUser();
    //const promise1=  this._getTransmittalSummaries();
    const promise2=  this._getAllControlDocuments();
    //const promise3=  this._getAllTransmittalItems();
    const Promise4= this._getDocumentStatus();
    const Promise5= this._getTransmittalComments();
    const Promise6 = this._getPdfTransmittalDownloadPath();
    const Promise7 = this._getCustomerReturnControl();
   let values = await Promise.all([promiseItemsAndSummaries, promise2,Promise4,Promise5,Promise6,Promise7]);
   let response1 = values[0][0];
   let responseItems = values[0][1];
   let responseDocs = values[1];
   let responseStatus = values[2];
   let resComments = values[3];
   let pdfPath = values[4];
   let _allSummaries : ITransmittalSummary[]  = [];
   let _allTItems : ITransmittalItem[] = [];
   let _docs: { Id: any; DocumentTitle: any; Document_x0020_NoId: any; Document_x0020_No: any; Document_x0020_NoCustomer_x0020_Doc_x0020_No:any, FileRef: any; Pages0: any; ReleasedCusVersion: any; fileSize: any;}[] = [];
   let _docStatus :IDocumentStatus[] = [];
   let _groups : IGroup[] = [];
   this._options = [];
   let resCusControl = values[5];
   let cusControlFlag = true;
   let message4DisableCusReturn = "";
   if (resCusControl.length === 1)
    {
      cusControlFlag = resCusControl[0]["Title"] === "ON" ? true : false;
      message4DisableCusReturn = cusControlFlag ? "": resCusControl[0]["Description"];
    }

    console.log(`cusControlFlag:${cusControlFlag}`);
    console.log(`message4DisableCusReturn:${message4DisableCusReturn}`);
   response1.map((item: { ID: any; Title: any; RevisionNo: { Title: any; }; CustomerTransmittalNo: string | null | undefined; }) =>{
     _allSummaries.push({
        Id: item.ID,
        ProjectNo: this.props.projectNo,
        TransmittalNo: item.Title,
        Revision:item.RevisionNo == null ? "" : item.RevisionNo.Title,
        FromUser:"",
        CustomerTransmittalNo:(item.CustomerTransmittalNo == null ? "" : item.CustomerTransmittalNo),
     });
    });
    responseStatus.map((item: { ID: any; Title: any; Description: any; CustomerVersion:any;}) => {
        _docStatus.push({
            Id: item.ID,
            Code: item.Title,
            Description:item.Description,
            ActionKey:item.CustomerVersion === null ? "" : item.CustomerVersion,
        });
    });
    responseStatus.map((item: { ID: any; Description: any; }) =>{
      this._options.push({
      key:item.ID,
      text:item.Description,
      });
    });
    responseDocs.map((item: { ID: any; Document_x0020_Title: any; Document_x0020_No: { Id: any; DocumentNo: any; Customer_x0020_Doc_x0020_No:any;}; FileRef: any; Pages0: any; ReleasedCusVersion: any; File:{Length:any}})=>{
        _docs.push({
            Id:item.ID,
            DocumentTitle:item.Document_x0020_Title,
            Document_x0020_NoId:item.Document_x0020_No ? item.Document_x0020_No.Id : 0,
            Document_x0020_No:item.Document_x0020_No ? item.Document_x0020_No.DocumentNo : '',
            Document_x0020_NoCustomer_x0020_Doc_x0020_No: item.Document_x0020_No ? item.Document_x0020_No.Customer_x0020_Doc_x0020_No : "",
            FileRef:item.FileRef,
            Pages0:item.Pages0,
            ReleasedCusVersion:item.ReleasedCusVersion,
            fileSize:item.File.Length,
        });
    });
    let indexItem : number = -1;
    let summaryTmp: ITransmittalSummary  = {} as ITransmittalSummary;
    let countTmp:number = 0;
    let countTmps:number[] = [];
   // let countFlag:boolean = false;

    responseItems = responseItems.filter((item: any)  => {
      // add `item.SequenceStageCode.Title === "3-Return"` to fix the transmittal resend issue.
      //return this.state.isHistoryTransmittal ? (item.Return_x0020_Date !== null) : (item.Return_x0020_Date === null && item.SequenceStageCode.Title === "3-Return");
      return this.state.isHistoryTransmittal ? (item.Return_x0020_Date !== null && item.SequenceStageCode.Title.includes("Close")) : (item.SequenceStageCode.Title.includes("Return"));
      //item.Return_x0020_Date.HasValue === (this.state.isHistoryTransmittal ?  true : false);
      //item.SequenceStageCode.Title.includes(this.state.isHistoryTransmittal ? "Close" : "Return");
      //&& (item.Summary_x0020_Id.Id === 21);
    })

    responseItems.map((item: { Summary_x0020_Id: { Id: number; }; Document_x0020_Id: { Id: any; }; Id: any; Title: any; Transmittal_x0020_request_x0020_: { Description: any; }; Scheduled_x0020_Return_x0020_Dat: any; Return_x0020_Date: any; Return_x0020_By:  { Title: any; }; Document_x0020_Status: { Id: number | undefined, Code:string, Description:string } | null; IncomingTransmittalNo: any; SequenceStageCode: { Title: any; }; },index: number) =>{
        let result1 = _allSummaries.find(obj => {
            return obj.Id === item.Summary_x0020_Id.Id
          });
          //let resultRes2 = responseDocs.

        if (result1 != null)
        {
          let result2 = _docs.find(obj => {return obj.Id === item.Document_x0020_Id.Id});
          let commentCounting : number = resComments.filter((obj: { Item_x0020_Bind_x0020_Id: any; }) => {return obj.Item_x0020_Bind_x0020_Id === item.Id}).length;
          //let transmittalSummaryGroupName = result1.CustomerTransmittalNo === "" ? result1.TransmittalNo + "_" + result1.Revision : result1.CustomerTransmittalNo + "_" + result1.Revision;
          let transmittalSummaryGroupName = result1.CustomerTransmittalNo === "" ? result1.TransmittalNo + "_" + result1.Revision : result1.CustomerTransmittalNo + "";
          _allTItems.push({
              Id:item.Id,
              //ItemNo:`${result1.CustomerTransmittalNo}-${item.Title.split("-").pop()}`,
              ItemNo:item.Title,
              TransmittalNo:result1.TransmittalNo,
              TransmittalRevision:result1.Revision,
              CustomerTransmittalNo:result1.CustomerTransmittalNo,
              DocId : result2 ==null? 0 : result2.Id,
              DocRevisionNo:result2 ==null? "" : result2.ReleasedCusVersion,
              DocumentNo:result2 ==null? "" :result2.Document_x0020_No,
              CustomerDocumentNo:result2 ==null? "" :result2.Document_x0020_NoCustomer_x0020_Doc_x0020_No,
              Pages : result2 == null? 0 : result2.Pages0,
              DocumentTitle : result2 == null ? "" : result2.DocumentTitle,
              DocumentUrl: result2 == null ? "" : result2.FileRef,
              docSize : result2 == null ? "0 kb" : `${Math.round(result2.fileSize/1024)} kb`,
              Key:item.Id,
              SummaryId:result1.Id,
              RequestForDescription:item.Transmittal_x0020_request_x0020_.Description,
              RequestReturnDate:item.Scheduled_x0020_Return_x0020_Dat,
              ReturnDate:item.Return_x0020_Date,
              DocStatusOptions:this._options,
              siteUrl:this.props.siteUrl,
              projectNo:this.props.projectNo,
              CurrentStatusId:item.Document_x0020_Status == null ? 0 : item.Document_x0020_Status.Id,
              CurrentStatus:item.Document_x0020_Status == null ? "" : item.Document_x0020_Status.Description,
              comment:commentCounting,
              stage:item.SequenceStageCode.Title,
              TransmittalSummaryGroupName:transmittalSummaryGroupName,
              ReturnNumber:item.IncomingTransmittalNo === null ? "" : item.IncomingTransmittalNo,
              ReturnBy:item.Return_x0020_By == null ? "" : item.Return_x0020_By.Title,
              pdfUrl: item.IncomingTransmittalNo === null ?  "" : `${pdfPath}/${item.IncomingTransmittalNo}.pdf`,
          });
        }
        else
        {
          console.log(`${item.Title} no linked summary`);
        }
    });

   _allTItems.forEach(item => {
     indexItem += 1;
     if (summaryTmp !== null && summaryTmp.Id === item.SummaryId)
     {
       if (countTmps.length > 0)
        {
          let lastIdx = countTmps.length -1;
          countTmps[lastIdx] += 1;
        }
      }
      else
      {
          //means new transmittal summary , so that create new group
          countTmp = 1;
          summaryTmp.Id = item.SummaryId!;
          countTmps.push(countTmp);
          let tGroup : IGroup = {
            key:item.SummaryId!.toString(),
            name:item.TransmittalSummaryGroupName,
            startIndex:indexItem,
            count:countTmp
          }
      _groups.push(tGroup);
    }
  });

//   //re-assign the groups' count
for(var i = 0; i < _groups.length; i ++)
{
_groups[i].count = countTmps[i];
}
//console.log(values);

console.log("==========allItems==========:");
console.log(_allTItems.length.toString());
console.log("==========allGroups=========:" );
console.log(_groups.length.toString());

   this.setState({
    columns:this._columns,
     items:_allTItems,
     itemsT:_allTItems,
     groups:_groups,
     docStatusOptions:this._options,
     docStatus:_docStatus,
     isBusy:false,
     allowCustomerReturn:cusControlFlag,
     message4DisableReturn:message4DisableCusReturn,
     selectionDetails:''
   });

  }

  private reGenerategroupswhenSort(nItems: ITransmittalItem[]) 
  {
    let indexItem0 : number = 0;
    // let summaryTmp: ITransmittalSummary  = {} as ITransmittalSummary;
    let _groups : IGroup[] = [];
    let _sortedItems: ITransmittalItem[] = [];

    // debugger;
    let gUnique = nItems.map(item => item.SummaryId).filter((value, index, self) => self.indexOf(value) === index)
    gUnique.forEach(tSId => {
      let itemInG = nItems.filter(o => o.SummaryId?.toString() === tSId?.toString());
      _sortedItems = _sortedItems.concat(itemInG);
      let item = itemInG[0];
      let currentIndex = indexItem0;
      indexItem0 += itemInG.length;
      let tGroup : IGroup = {
        key:tSId === undefined ? "" :tSId.toString(),
        name:item.TransmittalSummaryGroupName,
        startIndex:currentIndex,
        count:itemInG.length
      }
      _groups.push(tGroup);

    });
    nItems = _sortedItems;
     return [nItems,_groups] as const;
  }

  private _onRenderDetailsHeader(props?: IDetailsHeaderProps, _defaultRender?: IRenderFunction<IDetailsHeaderProps>) {
    return <DetailsHeader {...props!} ariaLabelForToggleAllGroupsButton={'Expand collapse groups'} />;
  }

  private _onRenderColumnFunction(item?: ITransmittalItem, index?: number, column?: IColumn,allowCustomerReturn?:boolean) {
    
    const value =
    item && column && column.fieldName ? item[column.fieldName as keyof ITransmittalItem] || '' : '';
    if (column!.fieldName === "CurrentStatusId")
    {
      if (item?.ReturnDate === null || (item?.ReturnDate !== null && item?.stage === "3-Return"))
      {
       return <DocStatusDropdown Disabled={!allowCustomerReturn} RefItem={item!} ></DocStatusDropdown>
      }
      else
      {//items.filter(item => item.text === 'test words')
        var sCode= item?.DocStatusOptions?.filter(s => s.key === value)[0]?.text;
        return <div data-is-focusable={true}>{sCode}</div>; 
      }
    }
    else if (column!.fieldName === "comment")
    {
      return <CommentsCounting refItem={item!} count={value as number} ></CommentsCounting>
    }
    else if (column!.fieldName === "ReturnNumber")
    {
      //return "<a href='https://pdmshelper-stage.azurewebsites.net/PDMS/Reports/TransmittalReturn/" + `${value}.pdf'></a>` //"${value}`></a>"
      //let link = 'https://pdmshelper-stage.azurewebsites.net/PDMS/Reports/TransmittalReturn/' + `${value}.pdf`;
    
      return <a href={item!.pdfUrl} target="_blank" rel="noreferrer">{value}</a>
    }
    else if (column!.fieldName === "RequestReturnDate")
    {
      if (value !== null && value !== '')
      {
      let formatedDateValue = DateHelper.FormatDateToShortDate(value as string);
      //let dateValue1 = DateHelper.Deserialize(value.toString());
      return <div data-is-focusable={true}>{formatedDateValue}</div>
      }
    }
    else if (column!.fieldName === "ReturnDate")
    {
      if (value !== null && value !== '')
      {
      let formatedDateValue = DateHelper.FormatDateToShortDate(value as string);
      //let dateValue1 = DateHelper.Deserialize(value.toString());
      return <div data-is-focusable={true}>{formatedDateValue}</div>
      }
    }
    else
    {
       return <div data-is-focusable={true}>{value}</div>; 
    }
  }

  private _onRenderColumn(item?: ITransmittalItem, index?: number, column?: IColumn) {
    
    const value =
    item && column && column.fieldName ? item[column.fieldName as keyof ITransmittalItem] || '' : '';
    if (column!.fieldName === "CurrentStatusId")
    {
      if (item?.ReturnDate === null || (item?.ReturnDate !== null && item?.stage === "3-Return"))
      {
       return <DocStatusDropdown Disabled={true} RefItem={item!} ></DocStatusDropdown>
      }
      else
      {//items.filter(item => item.text === 'test words')
        var sCode= item?.DocStatusOptions?.filter(s => s.key === value)[0]?.text;
        return <div data-is-focusable={true}>{sCode}</div>; 
      }
    }
    else if (column!.fieldName === "comment")
    {
      return <CommentsCounting refItem={item!} count={value as number} ></CommentsCounting>
    }
    else if (column!.fieldName === "ReturnNumber")
    {
      //return "<a href='https://pdmshelper-stage.azurewebsites.net/PDMS/Reports/TransmittalReturn/" + `${value}.pdf'></a>` //"${value}`></a>"
      //let link = 'https://pdmshelper-stage.azurewebsites.net/PDMS/Reports/TransmittalReturn/' + `${value}.pdf`;
    
      return <a href={item!.pdfUrl} target="_blank" rel="noreferrer">{value}</a>
    }
    else if (column!.fieldName === "RequestReturnDate")
    {
      if (value !== null && value !== '')
      {
      let formatedDateValue = DateHelper.FormatDateToShortDate(value as string);
      //let dateValue1 = DateHelper.Deserialize(value.toString());
      return <div data-is-focusable={true}>{formatedDateValue}</div>
      }
    }
    else if (column!.fieldName === "ReturnDate")
    {
      if (value !== null && value !== '')
      {
      let formatedDateValue = DateHelper.FormatDateToShortDate(value as string);
      //let dateValue1 = DateHelper.Deserialize(value.toString());
      return <div data-is-focusable={true}>{formatedDateValue}</div>
      }
    }
    else
    {
       return <div data-is-focusable={true}>{value}</div>; 
    }
  }

  // private _inlineUpdateStatus() :void
  // {
  //   //use this function to do the inline update
  //   console.log("Inline updating happen here");
  // }

  // private _onShowItemIndexInViewChanged = (event: React.MouseEvent<HTMLInputElement>, checked: boolean): void => {
  //   this.setState({ showItemIndexInView: checked });
  // };

  // private _onChangeCompactMode = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
  //   this.setState({ isCompactMode: checked });
  // };
  //#region popup dialog
  private triggerSubmit() {
    //find the comment counting item hidden button and click
    let element: HTMLElement = document.getElementById(`btnCancel`) as HTMLElement;
    element.click();
    this.props.onDissmiss();
  }
  private async _generateTransmittalnumber() {
    let currentUser = this.props.currentUserInProject;
    let namingType = "CTRAReturn"

    let result = await axios.get(`/api/v1/pdmstransmittal/generateTranReturnNo?projectNo=${this.props.projectNo}&namingType=${namingType}&userId=${currentUser?.key}`);
    this.setState({ 
      returnTransmittal:result.data.body,
      serviceMessage : '',
      popupBusy:false,
    });
  }  
  private async _getProjectPoNumner() : Promise<any> {
    let result = await axios.get(`/api/v1/pdmstransmittal/getProjectPONo?projectNo=${this.props.projectNo}&namingType=CTRAReturn`);
    return result.data.body;   
  }
  private async _getUserCompany() : Promise<any> {
    let userId = this.props.currentUserInProject?.key;
    let projectNo = this.props.projectNo;
    let result = await axios.get(`/api/v1/pdmstransmittal/getUserCom?projectNo=${projectNo}&userId=${userId}`);
    return result.data.body;   
  }
  private async _getRunningNumber() : Promise<any> {
    let result = await axios.get(`/api/v1/pdmstransmittal/getrunningNo?projectNo=${this.props.projectNo}&namingType=CTRA`);
    return result.data.body;   
  }

  setTransmittalNo = (event:any) =>
  {
    this.setState({
      returnTransmittal: event.target.value,
    });
  }

  private async __submitTra(){
                  //construct orequest for rejected items here
                  let request0 = {
                    "site":"pdmsuat",
                    "ProjectNo" : "E001Avepoint",//this.props.projectNo,
                    "docIds": [5],//rejectedDocIds,
                    "rejectIds": [2],//[rejectedIds],
                    "internalReview": "0"
                  } 
                  axios.post(`https://pdmsinterface.azurewebsites.net/api/v1/reject?code=PsFNWId/EEJbZqVlq7nK7OWZRxPlOzbFY3Zl4Xcp9ToCP9vvuD8Mqg==`,request0).then((result0) => {
                    if (result0.data.body.indexOf("Error") > 0)
                    {
                      this.setState({
                        serviceMessage:`Error happened: ${result0.data.body}`,
                      });
                    }
                    else
                    {
                      // var test = 'done';
                    }
                  })
  }
  
  private async submitTra() {
    //hide popup
    //check the running sequence, if valid then proceed, otherwise regenerate number and sibmit again. 
    //this.hideDialog();
    //#region call submit
    //alert('call submit return transmittal here');
    //check the document status before submit
    //#region  validation of the tick items
    this.setState({
      serviceMessage:"Validate ticked items ..."
    });
    let statusCheckFlag = true;
    let returnCheckFlag = false;
    //let crossReturnCheck = true;
    let oList = this._selection.getSelection();
    //let firstSummaryId = 0;
    let summaryIds: number[] = [];
    
    for (let i = 0; i < oList.length; i++) {
      let traItem = oList[i] as ITransmittalItem;
      let tmpSumId = traItem.SummaryId!;
      let traID = traItem.Id;
      summaryIds.push(tmpSumId);
      if (traItem.CurrentStatusId === 0) 
      {
        statusCheckFlag = false;
        break;
      }
      if (traItem.ReturnDate != null)
      {
        returnCheckFlag = true;
        break;
      }
      let docStatusId = traItem.CurrentStatusId;
      let request = {
        projectNo:this.props.projectNo === undefined ? "" : this.props.projectNo,
        itemId:traItem === undefined ? "" : traID,
        statusId:docStatusId,
       };

       if(traItem !== undefined){

        let statusUpdate = await axios.post('/api/v1/transmittalItems/updateStatus',request).then((res) => {
            traItem.CurrentStatusId = docStatusId;
          })
          console.log(statusUpdate);
       }
    }
    //if (false === statusCheckFlag || false === crossReturnCheck)
    if (false === statusCheckFlag)
    {
      alert(`Please assign status for the selected transmittal Items.`);
      this.setState({
        isBusy:false,
        submitting:false,
      })
      return;
    }
    if (true === returnCheckFlag)
    {
      alert(`Returned transmittals are not allow to be submitted.`);
      this.setState({
        isBusy:false,
        submitting:false,
      })
      return;
    }
    //#endregion
    this.setState({
      isBusy:true,
    })
    let transIds: number[] = [];
    let rejectedIds : number[] = [];
    let rejectedDocIds: number[] = [];
    this._selection.getSelection().forEach(item => {
      transIds.push((item as ITransmittalItem).Id);
    });
    //check all reject items 

    this._selection.getSelection().forEach(item => {
      transIds.push((item as ITransmittalItem).Id);
    });

    this._selection.getSelection().forEach(item => {
      let cId = (item as ITransmittalItem).CurrentStatusId;
      let itemf = this.state.docStatus.find(i =>
        i.Id === cId && ( i.Description.toLowerCase().indexOf('reject') > -1 || i.ActionKey.toLowerCase().indexOf('reject') > -1));
      if (itemf != null)
      {      
      rejectedIds.push((item as ITransmittalItem).Id);
      rejectedDocIds.push((item as ITransmittalItem).DocId!);
      }
    });



 

    // check running sequence here
    let idxSequence = this.state.returnTransmittal?.split("-").length! - 1;
    let currSequence = this.state.returnTransmittal?.split("-")[idxSequence]!;
    //http://localhost:5000/api/v1/pdmstransmittal/getCompareRunningSequence?projectNo=TestDIV3&namingType=CTRAReturn&key=Sequence&sequence=0003
    let sequenceRes = await axios.get(`/api/v1/pdmstransmittal/getRunningSequence?projectNo=${this.props.projectNo}&namingType=CTRAReturn&key=Sequence`);
    if (sequenceRes.data.body === currSequence)
    {
      this.setState({
        serviceMessage:"Transmittal number is valid ..."
      });
      let updateRequest = {
        projectNo : this.props.projectNo,
        namingType: "CTRAReturn",
      }
      //continue return here and need to update sequence 
      //http://localhost:5000/api/v1/pdmstransmittal/addRunningNo
      let updateSeqRes = await axios.post(`/api/v1/pdmstransmittal/addRunningNo`,updateRequest);
      if (updateSeqRes.status.toString() === "200")
      {
        this.setState({
          serviceMessage:"Running number updated, return is starting ..."
        });
        //continute return here
        console.log(`start return with current: ${currSequence}`);


            
        let request = {
          projectNo : this.props.projectNo,
          transmittalIds: transIds,
          currentUser:this.props.currentUserInProject,
          userId:this.props.currentUserInProject!.key,
          tranNoReturn:this.state.returnTransmittal!,
        }
        console.log(`request: ${request}`);
        axios.post(`/api/v1/transmittal/return`,request).then((result) => {
          if (result.data.body.indexOf("Error") > 0)
          {
            this.setState({
              serviceMessage:`Error happened: ${result.data.body}`,
            });
          }
          else
          {
                      //construct orequest for rejected items here
                      let request0 = {
                        "projectNo" : this.props.projectNo,
                        "docIds": rejectedDocIds,
                        "rejectIds": rejectedIds
                      };
          // let request0 = {
          //   "site":"pdmsuat",
          //   "ProjectNo" : this.props.projectNo,
          //   "docIds": rejectedDocIds,
          //   "rejectIds": rejectedIds,
          //   "internalReview": "0"
          // } 
            // let request0 = {
            //   "site":"pdmsuat",
            //   "ProjectNo" : "E001Avepoint",//this.props.projectNo,
            //   "docIds": [5],//rejectedDocIds,
            //   "rejectIds": [2],//[rejectedIds],
            //   "internalReview": "0"
            // } 
            //axios.post(`https://pdmsinterface.azurewebsites.net/api/v1/reject?code=PsFNWId/EEJbZqVlq7nK7OWZRxPlOzbFY3Zl4Xcp9ToCP9vvuD8Mqg==`,request0).then((result0) => {
              axios.post(`/api/v1/transmittal/externalReject`,request0).then((result0) => {
                //no checking the interface, just log the console message:
                console.log(result0.data.body);
              // if (result0.data.body !== "")
              // {
              //   this.setState({
              //     serviceMessage:`Error happened: ${result0.data.body}`,
              //   });
              // }
              // else
              // {
                                  // generate pdf here:      
                  this.setState({
                    //serviceMessage:`Return done with email info : ${result.data.body}; Start generate PDF file ... `,
                    serviceMessage:`Start generate PDF file ... `,
                  });   
                  const dataReq = {
                    tranNoReturn: this.state.returnTransmittal!,
                    dd: '',
                    projectNo: this.props.projectNo,
                    transmittalIds:transIds
                  };
                  axios.post(`/api/v1/transmittal/returnPDF`,dataReq).then((pdfResult) => {
                    this.setState({
                      serviceMessage:`PDF generated, start email sending ... `,
                    }); 

                    let emailInfo = result.data.body;
                      let emailRequest = {
                        summaryIds:summaryIds,
                        subject:`[${this.props.projectNo} ${this.state.returnTransmittal!}] by ${this.props.currentUserInProject!.name}`,//
                        emailInfo:emailInfo,
                        fileName:`${request.tranNoReturn}.pdf`,
                        projectNo: this.props.projectNo,
                      }
                  axios.post(`/api/v1/transmittal/emailReturnPDF`,emailRequest).then(()=>{
                      this.setState({
                        serviceMessage:'',
                      }); 
                      this.hideDialog();
                      this._buildDetailsListItems();
                      })    
                });
              // }
            })
          }
        });
    
      }
    }
    else
    {
      this.setState({
        serviceMessage:"Return process failed to update running number, please try again."
      });
    }
    //#endregion
  }
  
  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns,items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        // this.setState({
        //   announcedMessage: `${currColumn.name} is sorted ${
        //     currColumn.isSortedDescending ? 'descending' : 'ascending'
        //   }`,
        // });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    if (items.length > 0)
    {
      const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
      const newGroups = this.reGenerategroupswhenSort(newItems);
      this.setState({
        columns: newColumns,
        items: newGroups[0],
        groups:newGroups[1],
      });
  }
  };

  //#endregion
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  // debugger;
  let key = columnKey as keyof T;
  if (key === "docSize" || key === "Pages")
  {
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? parseFloat(String(a[key])) < parseFloat(String(b[key])) : parseFloat(String(a[key])) > parseFloat(String(b[key]))) ? 1 : -1));
  }
  else if (  key === "CurrentStatusId")
  {
    let nkey = "CurrentStatus" as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[nkey] < b[nkey] : a[nkey] > b[nkey]) ? 1 : -1));
  }
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));

}

// function comparefilesize( a:ITransmittalItem , b:ITransmittalItem ) {
//   if ( parseFloat(a.docSize?) < parseFloat(b.docSize?) ){
//     return -1;
//   }
//   if ( parseFloat(a.docSize?) > parseFloat(b.docSize?) ){
//     return 1;
//   }
//   return 0;
// }
