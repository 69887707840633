import * as React from 'react';
import { Icon,} from 'office-ui-fabric-react';
//import { ActivityItem, Link, mergeStyleSets, Panel } from 'office-ui-fabric-react';
//import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
// import {AssignmentItem} from './IAssignmentItem';
//import { Web } from "@pnp/sp/webs"; 
// import {ICommentMDLAssignment}  from './ICommentMDLAssignment';
// const classNames = mergeStyleSets({
//   exampleRoot: {
//     marginTop: '20px',
//   },
//   nameText: {
//     fontWeight: 'bold',
//   },
// });
import axios from 'axios';
import { IAssignment } from './IAssignmentItem';

export interface ICommentsCountingProps {
    count?:number;
    refItem:IAssignment;
    //setPanelState?:any;
  }

  export interface ICommentsCountingState
  {
      counted:string;
      refItem:IAssignment;
      //openPanel?:boolean;
  }

  const hideStyle = {display : 'none',maxWidth:'1px'};
//export const ActivityItemBasicExample: React.FunctionComponent = () => {
    export default class CommentsCountingMDLAssignment extends React.Component<ICommentsCountingProps, ICommentsCountingState> {

        constructor(props: ICommentsCountingProps | Readonly<ICommentsCountingProps>) {  
            super(props); 
            this.state={
                counted:this.props.count? this.props.count.toString() : "0",
                refItem:this.props.refItem
            };
          } 

          public render(): React.ReactElement<ICommentsCountingProps> {
            return (
              <div>
                <span onClick={()=>this._openCommentsListPanel()} style={{color: "red",cursor:"pointer"}}><Icon iconName={'FileComment'} />{this.state.counted}</span>
                <button title='btnComment' style={hideStyle} id={`btnCC${this.props.refItem.Id}`} onClick={()=>{this._countingComments()}}></button>
              </div>
            );
          }

          componentDidUpdate(previousProps:ICommentsCountingProps, previousState:ICommentsCountingState)
          {
              //this._countingComments();
          }


          private _openCommentsListPanel() : void
          {
              let element: HTMLElement = document.getElementById("btnPanelMDLAssignment") as HTMLElement;
              let element1: HTMLElement = document.getElementById("btnMDLId") as HTMLElement;
              let element2: HTMLElement = document.getElementById("btnRevision") as HTMLElement;
              let element3: HTMLElement = document.getElementById("btnDocumentNo") as HTMLElement;
              element.textContent= this.state.refItem.Id.toString();
              element1.textContent = this.state.refItem.mdlId!.toString();
              element2.textContent = this.state.refItem.DocRevisionNo!.toString();
              element3.textContent = this.state.refItem.documentNo.toString();
              element.title =  this.state.refItem.comment === undefined ? '0' : this.props.refItem.comment!.toString();
              element.click();
          }

          private async _getMDLAssignmentComments() : Promise<any>
          {
            let result = await axios.get(`/api/v1/mdls/commentsCounting?projectNo=${this.props.refItem.projectNo!}&refItemId=${this.props.refItem.Id}`);
            return result.data.body;
         }

         private async _countingComments()
         {
            const promise1=  this._getMDLAssignmentComments();
           let values = await Promise.all([promise1]);
           let response1 = values[0];
           let count:number = response1.length;
           var nRefItem = this.state.refItem;
           nRefItem.comment = response1.length.toString();
           this.setState({
               counted:"  " + count.toString(),
               refItem: nRefItem
           });
        
         }  

};
